@import "./../../scss/variables.scss";

.header {
  background: url(../../assets/img/header.png);
  background-size: cover;
  background-position: left;
  text-align: left;
  height: 100%;
}

.header__logo img {
  position: relative;
  width: 250px;
  margin: 0 auto;
  margin-left: 7rem;
  padding: 5rem 0 6rem;
  -webkit-animation: bounce 3s ease-in-out infinite;
  animation: bounce 3s ease-in-out infinite;
}

.header__title h1 {
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  color: $white;
  font-size: 3rem;
  font-weight: 900;
  line-height: 3.5rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  margin-left: 7rem;
}

.header__title h1:last-child {
  color: $green;
  padding-bottom: 13rem;
}

.header__title--img {
  height: 30px;
  padding-top: 1.5rem;
}

@media screen and (max-width: 360px) {
  .header__logo img {
    padding: 6rem 0 7rem;
    margin-left: 3.5rem;
  }

  .header__title h1 {
    margin-left: 5rem;
  }
}

@media screen and (min-width: 768px) {
  .header {
    background-position: center;
  }

  .header__logo img {
    padding: 6rem 0 7rem;
    margin-left: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    padding-top: 5rem;
    padding-left: 10rem;
    background-position: center;
    text-align: left;
  }

  .header__logo img {
    width: 250px;
    padding: 6rem 0 10rem;
    margin-left: 0;
  }

  .header__title h1 {
    margin-bottom: 4rem;
    margin-left: 2rem;
  }

  .header__title h1:last-child {
    padding-bottom: 19rem;
  }
}

@media screen and (min-width: 1280px) {
  .header__logo img {
    width: 300px;
    padding: 6rem 0 6rem;
  }

  .header__title h1 {
    margin-bottom: 4rem;
    margin-left: 2rem;
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

@media screen and (min-width: 1440px) {
  .header {
    padding-top: 6rem;
    padding-left: 12rem;
  }

  .header__logo img {
    width: 350px;
    padding: 6rem 0 7rem;
  }

  .header__title h1 {
    margin-bottom: 4rem;
    margin-left: 5rem;
    font-size: 4rem;
    line-height: 4.5rem;
  }

  .header__title h1:last-child {
    padding-bottom: 19rem;
  }
}

@media screen and (min-width: 1920px) {
  .header {
    padding-top: 8rem;
    padding-left: 30rem;
  }

  .header__logo img {
    width: 450px;
    padding: 6rem 0 14rem;
  }

  .header__title h1 {
    margin-bottom: 6rem;
    margin-left: 5rem;
    font-size: 6rem;
    line-height: 6rem;
  }

  .header__title h1:last-child {
    padding-bottom: 28rem;
  }
}

@import "./../../scss/variables.scss";

.footer {
  color: $white;
  background: url(../../assets/img/footer.png);
  background-size: cover;
  background-position: center;
  padding-top: 6rem;
}

.footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  font-size: 1rem;

  span {
    font-size: 1rem;
  }

  p {
    font-weight: bold;
  }
}

.footer__container--logo {
  fill: $white;
  height: 10px;
  margin-left: 0.2rem;
}

.footer__container--logo:hover {
  transform: scale(1.1);
}

@media screen and (min-width: 1024px) {
  .footer {
    color: $white;
    background: url(../../assets/img/footer.png);
    background-size: cover;
    background-position: center;
    height: 150px;
    padding-top: 9rem;
  }

  .footer__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    font-size: 1rem;

    span {
      font-size: 1rem;
    }

    p {
      font-weight: bold;
    }
  }

  .footer__container--logo {
    fill: $white;
    height: 10px;
    margin-left: 0.2rem;
  }

  .footer__container--logo:hover {
    transform: scale(1.1);
  }
}

@media screen and (min-width: 1440px) {
  .footer {
    height: auto;
    padding-top: 17rem;
  }

  .footer__container {
    font-size: 1.5rem;

    span {
      font-size: 1.5rem;
    }
  }

  .footer__container--logo {
    height: 15px;
    margin-left: 0.4rem;
  }
}

@media screen and (min-width: 1920px) {
  .footer {
    padding-top: 25rem;
  }

  .footer__container {
    padding: 2rem;
    font-size: 2rem;

    span {
      font-size: 2rem;
    }
  }

  .footer__container--logo {
    height: 20px;
    margin-left: 0.4rem;
  }
}
